html,
body {
  height: 100%;
}
.white {
  color: #ffffff;
}
.orange {
  color: #ff3100;
}
.dark {
  color: #212529;
}
.whitespace {
  margin-top: 50px;
}
.left {
  text-align: left;
}
.title {
  margin-bottom: 20px !important;
}
.showcase {
  margin-top: 50px;
}
.MainContainerImg {
  background-image: url(https://ajsliders.com/images/tagline_bg4.png);
}
.HeaderImg {
  background: url("../images/about_us.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.center {
  margin: 0 auto;
}
.MainContainerImg a {
  text-decoration: none;
  color: #ffffff !important;
}
.MainContainerImg .list-group-item {
  background-color: transparent;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.MainContainerImg button {
  margin: 20px 0;
}
.footer {
  margin-top: 20px;
  padding-bottom: 20px;
}
.footer .nav {
  margin-bottom: 10px;
}
.footer .nav-link {
  padding: 0.5rem 0.5rem;
}
.footer a {
  color: #5e5e5e;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
}

.footer a:hover {
  color: #1e1e1e;
}
.footer p {
  font-size: 12px;
  color: #5e5e5e;
}
.footer svg {
  color: tomato;
}
.heart svg {
  color: tomato;
}
@media (max-width: 979px) {
  .col-6 {
    width: 100%;
    margin-top: 50px;
  }
  .showcase {
    margin-top: 0;
  }
  .img-sm {
    width: 90%;
  }
  .video {
    width: 100% !important;
  }
  .dept-btn-cont {
    width: 80%;
  }
}

@media (min-width: 979px) {
  .topbtn {
    display: block;
    color: white;
    text-align: center;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.MobileContImg {
  background: url("../images/background.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: "100vw";
  height: "100vh";
  padding: 35px 0;
}
.MobileContImg a {
  text-decoration: none;
}
.our-dept-main {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  transition: 0.5s;
  margin-bottom: 15px;
  background: transparent;
  color: whitesmoke;
}
.dept-front {
  /* background: #fff; */
  idth: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  bottom: 0px;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 420px;
  padding: 15px 10px;
  margin: auto;
  box-shadow: 0 0 15px rgb(0 0 0);
}
.dept-front h3 {
  margin-top: 10px !important;
  margin-top: 10px !important;
  min-width: 183px;
  text-align: left;
  margin-left: 20px;
}

.dept-1 {
  background: linear-gradient(
    36deg,
    rgba(18, 66, 40, 1) 0%,
    rgba(16, 124, 65, 1) 50%,
    rgba(51, 196, 129, 1) 100%
  );
}
.dept-2 {
  background: linear-gradient(
    36deg,
    rgba(0, 93, 232, 1) 0%,
    rgba(44, 138, 254, 1) 50%,
    rgba(63, 169, 245, 1) 100%
  );
}
.dept-3 {
  background: linear-gradient(
    36deg,
    rgba(136, 33, 168, 1) 0%,
    rgba(137, 70, 202, 1) 50%,
    rgba(111, 112, 252, 1) 100%
  );
}
.dept-btn-cont {
  margin: 50px 0;
}

.MuiFormLabel-root {
  color: #000 !important;
  font-weight: 600 !important;
}

.MuiFormLabel-root span {
  color: rgb(243, 12, 12) !important;
  font-weight: 600 !important;
}

.promo-text {
  -webkit-text-decoration-line: line-through; /* Safari */
  text-decoration-line: line-through;
}
.MuiPaper-root.MuiAccordion-root {
    background-color:  #B56100 !important;
    color: white;
    padding: .35rem 0;
    margin-bottom: .25rem;
}
.MuiTypography-h5 {
    font-size: 1.125rem !important;
    margin-bottom: auto !important;
}
.MuiAppBar-positionFixed {
    background-color: rgba (0, 0, 0, 0.87);
    color: white !important;
}
@media (max-width: 600px) {
    .MuiToolbar-regular {
        padding-top: .25rem;
    }
    .MuiIconButton-edgeStart {
        margin-top: -1rem;
    }
    .MuiCardMedia-root {
        padding: .5rem;
    }
}
.MuiAccordionSummary-expandIconWrapper {
    color: rgba(254, 254, 254, 0.99) !important;
}
@media (min-width: 601px) {
    .MuiCardMedia-root {
        padding: 1rem;
    }
    .MuiPaper-root.MuiCard-root {
        align-items: start !important;
    }
}
.MuiTypography-caption {
    font-size: 1rem;
}
.MuiTypography-body1 {
    min-width: 75px;
}

@media (max-width: 360px) {
    .h1, h1 {
        font-size: calc(1.175rem + 1.5vw) !important;
    }
}
@media (max-width: 575px) {
    .MuiPaper-root.MuiCard-root {
        margin-left: auto !important;
        margin-right: auto !important;    
    }
}
@media (max-width: 991px) {
    .navbar-brand {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .OrderHeader .card {
        display: none;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .ContactHeader {
        height: 700px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .PhonerightImg {
        background-size: auto 80%;
    }
    .ContactHeader {
        height: 750px;
    }
}
.MuiImageList-root {
    width: auto!important;
}
.MuiCardActions-root {
    float: right !important;
}

.css-yk16xz-control {
    font-size: large !important;
}
.DateInput_input {
    font-size: large !important;
}
/*
.MuiContainer-root {
    margin-top: 1rem;
}
*/

.btn-outline-danger {
    color: #fff !important;
    border-color: #cc3333!important;
}
.btn-outline-danger:hover {
    color: #fff !important;
    background-color: #cc3333!important;
    border-color: #cc3333!important;
}
.btn-outline-danger:active {
    color: #fff !important;
    background-color: #cc3333!important;
    border-color: #cc3333!important;
}
.MuiSvgIcon-fontSizeSmall {
    font-size: 1.75rem !important;
}
.MuiBox-root {
    font-size: 1.25rem !important;
}
.MuiButton-label {
    width: max-content !important;
}
.MuiAppBar-positionFixed {
    position: fixed !important;
}
@media (min-width: 600px) {
    .MuiToolbar-regular {
        min-height: 10px !important;
    }
}
.MuiToolbar-regular {
    min-height: 75px !important;
}
@media (max-width: 991px) {
    .navbar {
        display: block !important;
    }
    .navbar-dark .navbar-toggler {
        margin-left: 24px;
    }
    .navbar-collapse {
        padding-left: 24px;
        padding-top: 24px;
    }
}
.navbar-brand {
    padding-left: 24px;
}



.App {
  text-align: center;

}

.SL-logo {
  height: 15vmin;
  pointer-events: none;
  margin-left: auto;
  margin-right: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .SL-logo {
/*    animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.silogo {
    width: 110px;
    pointer-events: none;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
.MuiGrid-root {
  background-color: #eaeaea;
}
*/

.css-1y8ugea {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}

.MuiInputLabel-root {
    font-size: 2rem;
}

.MuiInput-underline {
    font-size: 2.5rem;    
}

.MuiButton-root {
    font-size: 2rem;  
}

.MuiButtonBase-root {
    background-color: #B56100;  
}

.MuiButtonBase-root:hover {
    background-color: #565656 !important;  
}
/*
.MuiButtonBase-root:active {
    background-color: #0097BA !important;  
}
.MuiButtonBase-root:focus {
    background-color: #0097BA!important;  
}
*/

.css-yl0gsq {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
}
a:hover {
    color: #161616 !important;
}
.MuiPaper-root {
    box-shadow: none !important;
}
.MuiCardActionArea-root .MuiCardContent-root:hover {
        color: floralwhite;   
}
.font-size-changer .font-size-changer-buttons > div {
    float: none !important;
    border: none !important;
    line-height: 1px !important;
    width: 1px !important;
    height: 1px !important;
}
#target .MuiTypography-subtitle1 {
    font-size: large;
    line-height: 1;
    margin-bottom: .5rem;
}
#target .MuiTypography-subtitle2 {
    font-size: large;
    line-height: 1.2 !important;
    margin-top: .5rem;
}
.MuiTypography-caption {
    line-height: 1.2 !important;
    font-size: large;
}
#target label + .MuiInput-formControl {
    font-size: large;
}
#target .MuiTypography-body1 {
    font-size: large;
}
#target .MuiButton-label {
    font-size: large;
}
#target .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1.125);
}
.modal-footer {
    font-size: x-large !important;
}
#draw .MuiTypography-caption {
    font-size: large !important;
}
#draw .MuiTypography-subtitle2 {
    font-size: large !important;
}

/*
@media (min-width: 1200px) {
    #target .MuiImageList-root {
        max-width: 1600px !important;
        padding-left: 250px !important;
        padding-right: 250px !important;
    }  
}
@media (min-width: 900px) and (max-width: 1600px) {
    #target .MuiImageList-root {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }
}
*/
@media (min-width: 900px) and (max-width: 1024px) {
    .OrderNow {
        margin-top: 35% !important;
    }
}
@media (max-width: 899px) {
    #target {
        position: relative !important;
    }
    .OrderNow {
        margin-top: 0% !important;
    }
}
.makeStyles-root {
    height: 60% !important;
}
.LogoMenuHeader {
  background: url("../images/logomenuheader.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5px;
  padding-bottom: 10px;    
}
@media (max-width: 899px) {
.font-size-changer {
    height: 0px !important;
    }
#transparent {
        background-color: transparent;
        color: white;
        border-color: transparent;
/*        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;*/
        font-weight: bold;
    }    
}
.font-size-changer {
    height: 0 !important;
}